//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SliderCaptcha from "@/components/sliderCaptcha/sliderCaptcha.vue";
import loginApi from "@/api/login";
import { setToken, setUser } from "@/utils/auth";
import { encrypt } from "@/utils/rsaEncrypt";
export default {
  components: {
    SliderCaptcha,
  },
  data() {
    return {
      captchaLoading: false,
      loginLoading: false,
      timer: 60,
      isGetCaptcha: false,
      sliderSuccess: false,
      currentLoginType: "0",
      form: {
        phoneNum: null,
        password: null,
        captcha: null,
      },
      loginUuid: null,
      rules: {
        phoneNum: [{ required: true, message: "请输入手机号" }],
        password: [{ required: true, message: "请输入密码" }],
        captcha: [{ required: true, message: "请输入验证码" }],
      },
    };
  },
  methods: {
    reset() {
      this.captchaLoading = false;
      this.timer = 60;
      this.isGetCaptcha = false;
      this.sliderSuccess = false;
      if (this.$refs.sliderCaptcha) {
        this.$refs.sliderCaptcha.reset();
      }
    },
    changeLoginType(e) {
      this.currentLoginType = e;
      this.reset();
    },
    getLoginCaptcha() {
      this.$refs.form.validateField(["phoneNum"], () => {
        loginApi.getLoginCaptcha(this.form).then((res) => {
          if (res) {
            this.loginUuid = res.uuid;
          }
        });
      });
      this.captchaLoading = true;
      this.isGetCaptcha = true;
      let that = this;
      let timerTemp = this.timer;
      let interval = setInterval(() => {
        that.timer = that.timer - 1;
        if (that.timer <= 0) {
          clearInterval(interval);
          that.timer = timerTemp;
          this.captchaLoading = false;
        }
      }, 1000);
    },
    handleSliderSuccess() {
      this.sliderSuccess = true;
    },
    submit() {
      let that = this;
      that.$refs.form.validate((valid) => {
        if (valid) {
          this.loginLoading = true;
          setTimeout(() => {
            loginApi
              .login({
                ...this.form,
                password:
                  this.currentLoginType == 0
                    ? encrypt(this.form.password)
                      ? encrypt(this.form.password)
                      : null
                    : null,
                type: this.currentLoginType,
                uuid: this.loginUuid,
              })
              .then((res) => {
                console.log(res);
                this.loginLoading = false;
                if (res.success) {
                  setToken(res.token, true);
                  setUser(res.user, true);
                  that.$store.commit("setCurrentUser", res.user);
                  that.$emit("loginSuccess");
                } else {
                  this.$message.error(res.msg);
                }
              });
          }, 2000);
        }
      });
    },
    // testSubmit(){
    //   loginApi.test().then((res) => {
    //     console.log(res);
    //   });
    // }
  },
};
