//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SliderCaptcha from "@/components/sliderCaptcha/sliderCaptcha.vue";
import loginApi from "@/api/login";
import { encrypt } from "@/utils/rsaEncrypt";
import { setToken, setUser } from "@/utils/auth";
export default {
  inject: ["reload"],
  components: {
    SliderCaptcha,
  },
  data() {
    return {
      captchaLoading: false,
      isRegisteLoading: false,
      timer: 60,
      isGetCaptcha: false,
      sliderSuccess: false,
      form: {
        phoneNum: null,
        password: null,
        captcha: null,
      },
      /**用于后端redis的key查找验证码 */
      registeUuid: null,
      rules: {
        phoneNum: [
          { required: true, message: "请输入手机号" },
          {
            pattern: /^1[3|5|7|8|9]\d{9}$/,
            message: "请输入正确的号码格式",
          },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.captchaLoading = false;
      this.timer = 60;
      this.isGetCaptcha = false;
      if (this.$refs.sliderCaptcha) {
        this.$refs.sliderCaptcha.reset();
      }
    },
    getRegisteCaptcha() {
      this.$refs.form.validateField(["phoneNum"], (valid) => {
        loginApi.getRegisteCaptcha(this.form).then((res) => {
          if (res) {
            this.registeUuid = res.uuid;
          }
        });
      });
      this.captchaLoading = true;
      this.isGetCaptcha = true;
      let that = this;
      let timerTemp = this.timer;
      let interval = setInterval(() => {
        that.timer = that.timer - 1;
        if (that.timer <= 0) {
          clearInterval(interval);
          that.timer = timerTemp;
          this.captchaLoading = false;
        }
      }, 1000);
    },
    submit() {
      this.isRegisteLoading = true;
      let that = this;
      setTimeout(() => {
        loginApi
          .registe({
            ...this.form,
            password: encrypt(this.form.password),
            uuid: this.registeUuid,
          })
          .then(
            (res) => {
              this.isRegisteLoading = false;
              if (res) {
                this.$notify({
                  title: "成功",
                  message: "注册成功",
                  type: "success",
                });
                loginApi.login({ ...this.form,password: encrypt(this.form.password), type: '0' }).then((res) => {
                  console.log(res);
                  if (res.success) {
                    setToken(res.token, true);
                    setUser(res.user, true);
                    that.$store.commit("setCurrentUser", res.user);
                    that.$router.push("/homePage");
                  } else {
                    this.$message.error(res.msg);
                  }
                });
              } else {
                this.$notify.error({
                  title: "失败",
                  message: "注册失败，请重新尝试",
                });
              }
            },
            (err) => {
              this.isRegisteLoading = false;
            }
          );
      }, 2000);
    },
  },
};
