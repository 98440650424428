//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoginBox from "@/components/login/loginBox";
import Shpere from "@/components/3d/sphere";
export default {
  components: { Shpere, LoginBox },
  data() {
    return {
      action: {
        bgShow: false,
        titleShow: false,
        boxShow: false,
        from: null
      },
      currentTag: 0,
    };
  },
  mounted() {
    this.from = this.$route.query.from;
    this.action.bgShow = true;
    this.action.titleShow = true;
    this.action.boxShow = true;
    particlesJS.load("particles", "/particles.json");
  },
  methods: {
    //====================动画
    sphereAnime(el, done) {
      this.anime({
        targets: el,
        scale: [0.5, 1],
        opacity: [0, 1],
        easing: "linear",
        duration: 1000,
        delay: 1000,
        complete() {
          done();
        },
      });
    },
    engAnime(el, done) {
      this.anime({
        targets: el,
        translateX: [400, 0],
        opacity: [0, 1],
        easing: "easeOutCirc",
        duration: 800,
        delay: 1000,
        complete() {
          done();
        },
      });
    },
    subTitleAnime(el, done) {
      this.anime({
        targets: el,
        translateX: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutCirc",
        duration: 800,
        delay: 1000,
        complete() {
          done();
        },
      });
    },
    titleAnime(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    boxAnime(el, done) {
      this.anime({
        targets: el,
        translateX: [400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    //====================动画
    reset() {
      if (this.$refs.login) {
        this.$refs.login.reset();
      }
      if (this.$refs.registe) {
        this.$refs.registe.reset();
      }
    },
    clickTag(e) {
      this.currentTag = e;
      this.reset();
    },
    loginEnterAnime(el, done) {
      this.anime({
        targets: el,
        // translateX: [-200, 0],
        opacity: [0, 1],
        easing: "linear",
        duration: 200,
        complete() {
          done();
        },
      });
    },
    loginLeaveAnime(el, done) {
      this.anime({
        targets: el,
        // translateX: [200, 0],
        opacity: [1, 0],
        easing: "linear",
        duration: 200,
        complete() {
          done();
        },
      });
    },
    registeEnterAnime(el, done) {
      this.anime({
        targets: el,
        // translateX: [200, 0],
        opacity: [0, 1],
        easing: "linear",
        duration: 200,
        complete() {
          done();
        },
      });
    },
    registeLeaveAnime(el, done) {
      this.anime({
        targets: el,
        // translateX: [-200, 0],
        opacity: [1, 0],
        easing: "linear",
        duration: 200,
        complete() {
          done();
        },
      });
    },
    loginSuccess(){
      const path = this.$store.state.loginRecPath
      console.log("path rec:", path)
      this.$router.push({
        path,
      });
    }
  },
};
