//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import login from "@/components/login/login.vue";
import registe from "@/components/login/registe.vue";
export default {
  components: { registe, login },
  data() {
    return {
      currentTag: 0,
    };
  },
  methods: {
    reset() {
      if (this.$refs.login) {
        this.$refs.login.reset();
      }
      if (this.$refs.registe) {
        this.$refs.registe.reset();
      }
    },
    clickTag(e) {
      this.currentTag = e;
      this.reset();
    },
    loginEnterAnime(el, done) {
      this.anime({
        targets: el,
        // translateX: [-200, 0],
        opacity: [0, 1],
        easing: "linear",
        duration: 200,
        complete() {
          done();
        },
      });
    },
    loginLeaveAnime(el, done) {
      this.anime({
        targets: el,
        // translateX: [200, 0],
        opacity: [1, 0],
        easing: "linear",
        duration: 200,
        complete() {
          done();
        },
      });
    },
    registeEnterAnime(el, done) {
      this.anime({
        targets: el,
        // translateX: [200, 0],
        opacity: [0, 1],
        easing: "linear",
        duration: 200,
        complete() {
          done();
        },
      });
    },
    registeLeaveAnime(el, done) {
      this.anime({
        targets: el,
        // translateX: [-200, 0],
        opacity: [1, 0],
        easing: "linear",
        duration: 200,
        complete() {
          done();
        },
      });
    },
    loginSuccess() {
      this.$emit("loginSuccess");
    },
  },
};
